import styled from 'styled-components'

export const StyledHeading = styled.h1`
  font-size: 30px;
  color: #29323d;
  font-weight: normal;
  line-height: 30px;
  font-weight: 300;
  margin: 0;
`
