import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'

const MetaData = React.memo(({ title }) => (
  <Head>
    <title>{title}</title>
  </Head>
))

MetaData.propTypes = {
  title: PropTypes.string.isRequired,
}

export default MetaData
