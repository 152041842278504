import React from 'react'
import { getJSON } from '../libs/data'

const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: null,
  error: null,
}

const START = 'start'
const COMPLETED = 'completed'
const ERROR = 'error'

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      if (state.isLoading === true) {
        return state
      }
      return { ...state, isLoading: true }
    case COMPLETED:
      return { isLoading: false, hasLoaded: true, data: action.payload, error: null }
    case ERROR:
      return { isLoading: false, hasLoaded: true, data: null, error: action.payload }
    default:
      throw new Error()
  }
}

const useAPI = (initialUrl = '') => {
  const [state, dispatch] = React.useReducer(reducer, { ...initialState, isLoading: !!initialUrl })
  const [url, setUrl] = React.useState(initialUrl)

  React.useEffect(() => {
    let ignore = false
    const fetchData = async () => {
      try {
        dispatch({ type: START })

        const result = await getJSON(url)
        if (!ignore) dispatch({ type: COMPLETED, payload: result })
      } catch (e) {
        dispatch({ type: ERROR, payload: e })
      }
    }
    if (url) {
      fetchData()
    }
    return () => {
      ignore = true
    }
  }, [url])

  return [state, setUrl]
}

export default useAPI
