import React from 'react'
import PropTypes from 'prop-types'

import { StyledHeading } from './style'

const Heading = React.memo(({ children }) => <StyledHeading>{children}</StyledHeading>)

Heading.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Heading
